.KLJSLFJ {
	position: absolute;
	inset: 0px;
	box-sizing: border-box;
	padding: 0px;
	border: none;
	margin: auto;
	display: block;
	width: 0px;
	height: 0px;
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
}

.LAKFAALK {
	box-sizing: border-box;
	display: inline-block;
	overflow: hidden;
	width: initial;
	height: initial;
	background: none;
	opacity: 1;
	border: 0px;
	margin: 0px;
	padding: 0px;
	position: relative;
	max-width: 100%;
}

.WEIANFKA {
	box-sizing: border-box;
	display: block;
	width: initial;
	height: initial;
	background: none;
	opacity: 1;
	border: 0px;
	margin: 0px;
	padding: 0px;
	max-width: 100%;
}

.FAWOFJLA {
	display: block;
	max-width: 100%;
	width: initial;
	height: initial;
	background: none;
	opacity: 1;
	border: 0px;
	margin: 0px;
	padding: 0px;
}

.AFILKAJEF {
	position: absolute;
	inset: 0px;
	box-sizing: border-box;
	padding: 0px;
	border: none;
	margin: auto;
	display: block;
	width: 0px;
	height: 0px;
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
}

.AIOFEEWA {
	display: block;
	position: relative;
	top: 0px;
	left: 0px;
	height: 0px;
	width: 0px;
	border: 0px;
}

.FOAIEJA {
	color: transparent;
	max-width: 100%;
	height: auto;
}

.OFAIJLJN {
	box-sizing: "border-box";
	display: "inlineBlock";
	overflow: "hidden";
	width: "28px";
	height: "18.8px";
	background: "none";
	opacity: 1;
	border: "0px";
	margin: "0px";
	padding: "0px";
	position: "relative";
}

.S9gUrf-YoZ4jf, .S9gUrf-YoZ4jf * {
    border: none;
    margin: 0;
    padding: 0;
}