.nav_search-navbar__B_TGY {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav_search-navbar__B_TGY::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent;
}
.nav_search-navbar__B_TGY .nav_nav__fL7mg {
  position: relative;
  width: 16.666667%;
  flex-shrink: 0;
  border-bottom-width: 2px;
  border-color: transparent;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(160 175 191 / var(--tw-text-opacity));
}
.nav_search-navbar__B_TGY .nav_nav__fL7mg:hover {
  --tw-text-opacity: 1;
  color: '#007bff';
}
@media (min-width: 640px) {
  .nav_search-navbar__B_TGY .nav_nav__fL7mg {
    width: 25%;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .nav_search-navbar__B_TGY .nav_nav__fL7mg {
    width: 16.666667%;
  }
}
.nav_search-navbar__B_TGY .nav_nav__fL7mg,
.nav_search-navbar__B_TGY .nav_nav__fL7mg p {
  font-family: FilsonPro-Medium, sans-serif !important;
}
.nav_search-navbar__B_TGY .nav_active-bar__FGbx_ {
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  z-index: 20;
  height: 0.5rem;
  width: 100%;
  border-style: none;
  background-color: transparent;
}
@media (min-width: 640px) {
  .nav_search-navbar__B_TGY .nav_active-bar__FGbx_ {
    height: 0.625rem;
  }
}
.nav_search-navbar__B_TGY .nav_active-bar__FGbx_ {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.nav_search-navbar__B_TGY .nav_search-nav-active__00eqb {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(37 33 23 / var(--tw-text-opacity));
  font-family: FilsonPro-Medium, sans-serif !important;
}
.nav_search-navbar__B_TGY .nav_search-nav-active__00eqb .nav_active-bar__FGbx_ {
  --tw-bg-opacity: 1;
  background-color: #007bff;
}
@media (min-width: 1024px) {
  .nav_nav-base__wypU6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
.nav_nav-base__wypU6 {
  border-bottom: 1px solid #d5d8db;
}
.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}
.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}
.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #3e98c7;
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}
/*# */
